body {
  background-color: black;
  color: white;
  overflow: clip;
}

.app {
  width: 100%;
  height: 100vh;
}

.chapter-view {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.chapter-view-status {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.app-header {
  position: fixed;
  top: 0;
  background-color: #202020;
  padding: 5px;
  width: 100%;
  height: 22px;
}

.app-body {
  padding: 5px;
  margin-top: 27px;
  height: 100vh;
  overflow: scroll;
  /*width: 100%;*/

  /* hide scrollbars */
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.app-body::-webkit-scrollbar {
  display: none;
}

.section {
  text-indent: 10px
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
